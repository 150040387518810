const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#007bff',
  colorGreen : '#44783b'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"trajan-pro-3",serif',

  primaryColor: colors.colorBlue,
  fontColor: 'black',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;