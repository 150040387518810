//import { mq } from '../../cssInJs';

export function getShareButtonStyle() {
  return {
    shareButtons: {
      marginTop: '1.5rem',
    },
    shareButtonTitle: {
      fontSize: '0.8rem',
      marginLeft: '0.5rem',
      marginBottom: '0.3rem'
    },
    shareButton: {
      margin: 5
    },
  }
} 
