import React from "react";
import { LoadingCircle } from "../../icons";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export function Loading({ children = undefined }) {
  const [loaded, setLoaded] = React.useState(false);

  const style = {
    loading: css({
      minHeight: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: "0",
      transition: "all 1s",
    }),
    loaded: css({
      opacity: "1",
    }),
  };

  React.useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div css={[style.loading, loaded ? style.loaded : {}]}>
      <div>
        <LoadingCircle />
        {children}
      </div>
    </div>
  );
}
