/** @jsx jsx */
import { jsx } from '@emotion/core'
import { getButtonStyle } from './buttonStyle';

export function FormButton({ onClick, status, children, buttonStyle, overridingStyle, to, ...other }) {
  const s = getButtonStyle(buttonStyle);
  const ss = overridingStyle || {};
  return <a css={[s.button, ss.button]} href="/" onClick={onClick} type="button" disabled={status === 'pending'} {...other}>
    <span css={[s.buttonBg, ss.buttonBg]} className="buttonBg"></span>
    <span css={[s.buttonTxt, ss.buttonTxt]}>
      {status === 'pending' && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
      {children}</span>
  </a>
}
