import * as React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel, getLabelAsString } from '../formRenderHelper';

function TextInput({ control, handleChange, formOption }) {
    const { name, label, type, value, errors, style, helpText, attrs, forceUsePlaceholder } = control;
    //const { template = 'bootstrap', usePlaceholder = false } = formOption;
    const fieldId = utils.random.shortId();
    const { usePlaceholder = false } = formOption;
    const fieldLabel = getLabel(control, formOption);
    //console.log(control, formOption)
    const placeHolder = usePlaceholder ? getLabelAsString(control, formOption) : null;
    let valueStr = value === 0 ? 0 : (value || "");
    if (type === 'date' && value) {
        valueStr = utils.date.formatYYYYMMDD(value);
    }

    let prependText = null;
    if (style && style.prependText) {
        prependText = style.prependText
    }
    //console.log(name, !!(style && style.autoFocus))
    //const autoFocus = !!(style && style.autoFocus)

    function keyDown(e) {
        //console.log(e.key)
        if (e.key === "Enter" || e.key === 'Tab') {
            handleChange(e, control)
        }
    }
    function changed(e) {
        //console.log('changed', name, e.target.value)
        handleChange(e, control)
    }
    function blur(e) {
        //console.log('blur', name, e.target.value)
        handleChange(e, control)
    }

    // function focus(e) {
    //     console.log('focus', name, e.target.value)
    // }

    return (
        <div className={`ff ff--${type} form-group ${style && style.className}`}>
            {!usePlaceholder && fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className={`ff__input ${prependText && 'input-group'}`}>
                {prependText && <div className="input-group-prepend">
                    <div className="input-group-text">{prependText}</div>
                </div>
                }

                <input id={fieldId} name={name} type={type} value={valueStr} //onChange={(e) => handleChange(e, control)} 
                    // onBlur={(e) => handleChange(e, control, true)}
                    onChange={changed}
                    onKeyDown={keyDown}
                    onBlur={blur}
                    autoComplete="new-password"
                    //onFocus={focus}
                    //autoFocus={autoFocus}
                    placeholder={(forceUsePlaceholder && forceUsePlaceholder !== '') ? forceUsePlaceholder : placeHolder}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')} {...attrs} />
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>
    )
}

export default TextInput;