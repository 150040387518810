import React from "react";
import ValidationAlert from "../components/ValidationAlert";
import utils from "../../../utils";
import { getLabel } from "../formRenderHelper";
import { SiteLink } from "../../../components";

const CheckboxInput = ({ control, handleChange, formOption }) => {
  const {
    name,
    label,
    type,
    value,
    errors,
    style,
    helpText,
    attrs,
    link,
    linkText,
  } = control;
  const fieldId = utils.random.shortId();
  const fieldLabel = getLabel(control, formOption);
  return (
    <div className={`form-group form-check ${style && style.className}`}>
      <input
        id={fieldId}
        name={name}
        type={type}
        value={value || ""}
        checked={value || false}
        onChange={(e) => handleChange(e, control)}
        className={"form-check-input"}
        {...attrs}
      />
      <label htmlFor={fieldId} className="form-check-label">
        {fieldLabel}
        {link &&
          link !== "" &&
          linkText &&
          linkText !== "" &&
          (link.includes(".pdf") ? (
            <a href={link} className="checkbox_link_text">
              {" "}
              {linkText}{" "}
            </a>
          ) : (
            <SiteLink to={link} className="checkbox_link_text">
              {" "}
              {linkText}{" "}
            </SiteLink>
          ))}
      </label>

      {helpText && (
        <div
          className="ff__help"
          dangerouslySetInnerHTML={{ __html: helpText }}
        ></div>
      )}
      <ValidationAlert errors={errors} />
    </div>
  );
};

export default CheckboxInput;
