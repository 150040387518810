import { Required } from './validators/required';
import { Email } from './validators/email';
import { ValidateDate, PastDate, PastDateNullAllowed } from './validators/date';
import { Length } from './validators/length';

const validateControl = (control, e, validateOpt) => {
  const errors = [];
  const validators = control.validators || [];
  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];
    if (!validator.disabled) {
      const error = validator.validate && validator.validate(control, e, validateOpt, validator);
      if (error) {
        errors.push(error);
        //console.log(error, control, validator, e)

        if (!validator.continueToValidate) break;
      }
    }
  }
  control.errors = errors;
  return errors;
};

export default {
  Required,
  Email,
  Date: ValidateDate,
  PastDate,
  PastDateNullAllowed,
  Length,
  validateControl,
};
