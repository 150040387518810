import React from 'react';
import env from '../../../env';
import { usePost } from '../../../components';
import { useForm } from '../../../form';
import { ErrorMessage } from '../../../components';
import { getModel } from './subscribeFormModel';
import { gtm } from '../../../lib/tracking';
import { FormButton } from '../../button/formButton';
import { variables } from 'cssInJs';

import { darken } from 'polished';

export function Subscribe() {

  const model = getModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    form.validateForm(e, () => {
      const value = { ...form.getValue() };
      post.send(env.apiBase + "/api/contact/subscribe", value);
      setSendingStatus('pending')
    })
  }


  if (post.done() && sendingStatus === 'pending') {
    var value = form.getValue();
    gtm.sendEvent('subscribeFormSubmitted', {contactEmail: value.email} )
    form.reset(model)
    setSendingStatus(null)
  }

  const render = (name) => form.renderControl(name, null);

  const overridingStyle = {
    button: {
      '&:hover .buttonBg': {
        background: darken(0.1, variables.colorBlue),
      },
    },
    buttonBg: {
      background: variables.colorBlue,
    },
    buttonTxt: {
      fontWeight: 900,
      color: 'white'
    },
  }

  return (
    <form className="contactForm">
      <div className="form-row">
        <div className="col-md-6 mb-2">{render('name')}</div>
        {/* <div className="col-md-4 mb-2">{render('phone')}</div> */}
        <div className="col-md-6 mb-2">{render('email')}</div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-2">
          
<p style={{ textAlign: 'center'}}>By subscribing to Ecotourism Australia’s monthly newsletter, you accept the terms of our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
          <div className="actions">
            <h4><FormButton onClick={onSubmit} status={post.status} overridingStyle={overridingStyle}>SUBSCRIBE NOW</FormButton></h4>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
            {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>Successfully registered!</span>}
          </div>
        </div>
      </div>
    </form>
  );
}