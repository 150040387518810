import React from 'react';
import { createPortal } from "react-dom";

export function Portal({children}){
  const [el] = React.useState(document.createElement('div'));
  React.useEffect(() => {
      let mount = document.getElementById("portal-root");
      mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}